// config
const fontMin = 24; // px
const fontMax = 36; // px
const valueAttributeName = 'tag-value'; // tag in which we puted value
const tagsSelector = '[tag-value]'; // tags elemnts selector

// mechanism
const values = [];
const tags = Array.from(document.querySelectorAll(tagsSelector));
tags.forEach(tag => {
    const tagValue = tag.getAttribute(valueAttributeName);

    values.push({
        el: tag,
        val: Number(tagValue)
    })
});

const valuesSorted = values.sort((a, b) => a.val - b.val);
const valueMax = valuesSorted[valuesSorted.length - 1].val;

valuesSorted.forEach(x => {
    const {val, el} = x;

    const fontSize = Math.floor(
        (val / valueMax) * (fontMax - fontMin + 1) + fontMin
    );

    el.style.fontSize = `${fontSize}px`;
});
